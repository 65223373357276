// link the userform and listen for submission
const form = document.getElementById("new--enquiry");

form.addEventListener("submit", function (event) {
  // temporary code to prevent the page from reloading upon form submission
  // event.preventDefault();

  // store form data
  const formData = {
    firstName: document.forms["new--enquiry"]["fname"].value,
    lastName: document.forms["new--enquiry"]["lname"].value,
    company: document.forms["new--enquiry"]["company"].value,
    email: document.forms["new--enquiry"]["em"].value,
    phone: document.forms["new--enquiry"]["ph"].value,
    enquiry: document.forms["new--enquiry"]["enquiry"].value,
  };

  // Validate each form field

  function validateForm() {
    const validationResults = [];
    let errorMessage = document.getElementById("error-message");

    if (formData.firstName !== "") {
      validationResults.push(validateName(formData.firstName));
    } else {
      validationResults.push(false);
      errorMessage.textContent = "Please enter a first name.";
      errorMessage.style.color = "red";
    }

    if (formData.lastName !== "") {
      validationResults.push(validateName(formData.lastName));
    } else {
      validationResults.push(false);
      errorMessage.textContent = "Please enter a last name.";
      errorMessage.style.color = "red";
    }

    formData.company !== ""
      ? validationResults.push(validateCompany(formData.company))
      : validationResults.push(true);

    if (formData.email !== "") {
      validationResults.push(validateEmail(formData.email));
    } else {
      validationResults.push(false);
      errorMessage.textContent = "Please enter a valid email address.";
      errorMessage.style.color = "red";
    }

    formData.phone !== ""
      ? validationResults.push(validatePhone(formData.phone))
      : validationResults.push(true);

    if (formData.enquiry !== "") {
      validationResults.push(validateEnquiry(formData.enquiry));
    } else {
      validationResults.push(false);
      errorMessage.textContent = "Please enter your enquiry.";
      errorMessage.style.color = "red";
    }

    return validationResults.every((value) => value === true);
  }

  // Call the validation function
  if (!validateForm()) {
    console.log("Validation Failure");
    event.preventDefault(); // Results are not submitted upon validation failure
  } else {
    console.log("Validation completed");
    // Results are submitted upon validation success
  }
});

// linked modules
import { ValidationFunctions } from "./validation methods.js";

// Name validation functions
function validateName(formData) {
  const validate = new ValidationFunctions();
  const results = [];
  const cleanName = validate.inputTrim(validate.inputSanitization(formData));
  results.push(validate.isBlank(formData));
  results.push(validate.isString(formData));
  results.push(validate.nameFormat(formData));
  results.push(validate.inputLength(formData, 50));
  if (validate.isValidated(results) === true) {
    return true;
  }
}

// Company functions
function validateCompany(formData) {
  const validate = new ValidationFunctions();
  const results = [];
  const cleanCompany = validate.inputTrim(validate.inputSanitization(formData));
  results.push(validate.isString(formData));
  results.push(validate.companyFormat(formData));
  results.push(validate.inputLength(formData, 40));
  if (validate.isValidated(results) === true) {
    return true;
  }
}

// Email functions
function validateEmail(formData) {
  const validate = new ValidationFunctions();
  const results = [];
  const cleanEmail = validate.inputTrim(validate.inputSanitization(formData));
  results.push(validate.isBlank(formData));
  results.push(validate.isString(formData));
  results.push(validate.emailFormat(formData));
  results.push(validate.inputLength(formData, 254));
  if (validate.isValidated(results) === true) {
    return true;
  }
}

// Phone functions
function validatePhone(formData) {
  const validate = new ValidationFunctions();
  const results = [];
  const cleanPhone = validate.inputTrim(validate.inputSanitization(formData));
  results.push(validate.phoneFormat(formData));
  results.push(validate.inputLength(formData, 15));
  if (validate.isValidated(results) === true) {
    return true;
  }
}

// Enquiry functions
function validateEnquiry(formData) {
  const validate = new ValidationFunctions();
  const results = [];
  const cleanEnquiry = validate.inputTrim(validate.inputSanitization(formData));
  results.push(validate.isBlank(formData));
  results.push(validate.isString(formData));
  results.push(validate.enquiryFormat(formData));
  results.push(validate.inputLength(formData, 1000));
  if (validate.isValidated(results) === true) {
    return true;
  }
}
