export class ValidationFunctions {
  constructor() {}

  isBlank(formEntry) {
    return formEntry !== "" ? true : false;
  }

  isString(formEntry) {
    return typeof formEntry === "string" ? true : false;
  }

  inputLength(formEntry, maxLength) {
    return formEntry.length > 1 && formEntry.length < maxLength ? true : false;
  }

  emailFormat(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  nameFormat(name) {
    const nameRegex = /^[A-Za-zÀ-ÿ,.'-]+$/;
    return nameRegex.test(name);
  }

  companyFormat(company) {
    const nameRegex = /^[A-Za-zÀ-ÿ,.'-\s]+$/;
    return nameRegex.test(company);
  }

  phoneFormat(phone) {
    const sanitizedPhone = phone.replace(/[\s]/g, "");
    const phoneRegex =
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:- |(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    return phoneRegex.test(sanitizedPhone);
  }

  enquiryFormat(enquiry) {
    const enquiryRegex = /^[A-Za-z0-9 .,?!'"\-()]*$/;
    return enquiryRegex.test(enquiry);
  }

  inputSanitization(formEntry) {
    const sanitizedFormEntry = formEntry.replace(/[^A-Za-z'-]/g, "");
    return sanitizedFormEntry;
  }

  inputTrim(formEntry) {
    const trimmedFormEntry = formEntry.trim();
    return trimmedFormEntry;
  }

  isValidated(results) {
    return results.every((value) => value === true);
  }
}
